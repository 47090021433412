<template>
  <vue-perfect-scrollbar v-once class="scrollFrame scroll-area" :settings="settings">
    <section class="inneriframe">
      <h3>第1条 総則</h3>
      <p>
        <ul>
          <li>
            本規約は、株式会社プラグ（以下、「当社」といいます。）が所有、運営するウェブサイト『キャラクター価値AI』を通じて行うサービス（以下、「本サービス」といいます。）の利用に関して、当社が登録を承認した対象者（以下、「会員」といいます。）と当社との間における権利義務関係を定めることを目的とするものとします。
          </li>
        </ul>
      </p>
      <br>
      <h3>第2条 本規約の適用範囲及び変更</h3>
      <p>
        <ol style="list-style-type: decimal">
          <li>本規約は本サービスの提供及びその利用に関し、当社及び会員(第４条で定義します)に適用されるものとします。</li>
          <li>当社は、会員の事前の承諾を得ることなく、本規約を変更できるものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第3条 本サービスの利用</h3>
      <p>
        <ul>
          <li>会員は、本規約及び当社が別途定める利用ガイドなどに従い、本サービスを利用するものとします。</li>
        </ul>
      </p>
      <br>
      <h3>第4条 会員権</h3>
      <p>
        <ul>
          <li>「会員」とは、第5条で定めるところにより、本サービスの利用又は当社商品の購入を希望して登録（以下「会員登録」といいます。）を行い、当社が本サービスの利用を承諾した者を指します。また、本サービスの会員登録を行った場合は、本規約に会員が同意したものとみなします。</li>
        </ul>
      </p>
      <br>
      <h3>第5条 会員の資格</h3>
      <p>
        <ol>
          <li>当社の定める会員資格は、本規約を承諾の上、所定の入会手続きを全て完了した時点で有効に発行されるものとします。</li>
          <li>前項の定めに拘わらず、当社が本規約その他諸規定等に基づき会員として承認することを不適当と判断した場合、当社は会員資格の取り消しを行うことができるものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第6条 IDおよびパスワードの管理</h3>
      <p>
        <ol>
          <li>会員は、会員登録後本サイトを利用する場合には、当社又は会員が登録したID及びパスワードを使用するものとします。</li>
          <li>会員は、自己のID、パスワードの管理とその使用について一切の責任を負うものとします。定期的なパスワードの変更を推奨します。</li>
          <li>会員は、前条所定のID及びパスワードを譲渡、売買、貸与その他これに類する行為を行ってはならないものとします。</li>
          <li>当社は、会員又は第三者による前条所定のID及びパスワードの使用、管理等に関連して生じた一切の損害について、当該使用、管理等に関する故意過失の有無に拘わらず、一切の責任を負わないものとします。</li>
          <li>不正アクセスの対策としてアカウントロックシステムを採用しています。10回連続でログインに失敗するとID及びパスワードは使用できなくなります。</li>
        </ol>
      </p>
      <br>
      <h3>第7条 会員の登録情報</h3>
      <p>
        <ol>
          <li>会員は、会員登録の際に申告する登録情報の全ての項目（以下、「登録情報」といいます。）に関して、いかなる虚偽の申告も行ってはならないものとします。</li>
          <li>会員は、会員登録後、当社が定期的に行う登録情報の更新手続きに関して、当社が別途定める期間内に当該更新手続きを行うものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第8条 登録情報の管理</h3>
      <p>
        <ul>
          <li>当社は、会員情報の管理について、情報の改ざん、漏えい、不正アクセスが起きないよう、予防および安全対策を講じるものとします。</li>
        </ul>
      </p>
      <br>
      <h3>第9条 個人情報の取扱いと請求等問合せ窓口</h3>
      <p>
        <ol>
          <li>登録された個人情報は、当社の個人情報保護方針に基づき、利用者情報としての管理、通常連絡、購入手続き及び発送処理、経理処理のために利用します。また、本サービスの情報の追加や更新等についての配信や連絡を行うために利用することがあります。また、登録された個人情報は、利用目的の実施の範囲内において、業務委託先に委託することがあります。それ以外の目的での利用や第三者への提供は行いません。</li>
          <li>
            個人情報保護方針・個人情報の取扱いに関する問合せ窓口
            <br>
            03-5577-7810
            <br>
            個人情報保護管理者 村川奈緒美：privacy@plug-inc.jp
          </li>
          <li>【個人情報の開示等に関するお問い合わせ】 お預かりしました個人情報（開示対象個人情報）について、利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止（開示等）の求めがあった場合は、遅滞なく対応いたします。お問い合わせは上記窓口までお願いいたします。</li>
        </ol>
      </p>
      <br>
      <h3>第10条 機密保持事項</h3>
      <p>
        <ul>
          <li>本サービスを利用する会員が保有するデータ等の秘密情報の保護を目的とした下記事項は弊社が責任をもって機密保持に努めます。</li>
        </ul>
        <ol>
          <li>すべての結果データ</li>
          <li>アカウントやログイン履歴等の情報</li>
          <li>その他本サービスにかかわるデータ一式</li>
        </ol>
      </p>
      <br>
      <h3>第11条 会員の禁止行為</h3>
      <p>
        <ul>
          <li>会員は、以下に該当する行為またはその恐れのある行為を行ってはならないものとします。当社において、会員が禁止事項に違反したと認めた場合、利用の一時停止、退会処分、その他当社が必要と判断した措置をとることができます。</li>
        </ul>
        <ol>
          <li>公序良俗に反する行為</li>
          <li>法令、本規約その他当社が定める諸規定に違反する行為</li>
          <li>当社、他の会員または第三者を誹謗、中傷する行為</li>
          <li>IDまたはパスワードを不正に使用し、または使用させる行為</li>
          <li>当社、他の会員または第三者に不利益または損害を与えることを目的とした行為</li>
          <li>有害なコンピュータプログラム等を送信しまたは書込む行為</li>
          <li>本サイトにより利用しうる情報を改竄する行為</li>
          <li>本サービスの運営を妨害する行為</li>
          <li>虚偽の登録を行う行為</li>
          <li>当社が承認していない本サービスに関する営業活動またはその性格を有するおそれのある行為</li>
          <li>その他、当社が不適当と判断する行為</li>
        </ol>
      </p>
      <br>
      <h3>第12条 登録内容の変更</h3>
      <p>
        <ul>
          <li>会員は、会員登録の際に申告した内容に変更があった場合、すみやかにその旨を当社が指定する方法により届け出るものとします。</li>
        </ul>
      </p>
      <br>
      <h3>第13条 会員登録解除</h3>
      <p>
        <ol>
          <li>会員は、会員登録解除を希望する場合、当社に届け出るものとします。</li>
          <li>会員登録解除の申し出があった場合、当社はID及びパスワードを削除します。削除手続き以降、会員は履歴等の情報を閲覧できなくなります。</li>
          <li>当社は会員登録解除後も、一定期間内会員の情報を保存する場合があります。</li>
        </ol>
      </p>
      <br>
      <h3>第14条 会員資格の抹消</h3>
      <p>
        <ol>
          <li>当社は、会員の行為が以下のいずれかに該当する場合、何らの催告を要することなく、直ちに会員資格を抹消し、契約の全部または一部を将来に向かって解除することができるものとします。</li>
          <li>前項の解除は、相手方に対する損害賠償の請求を妨げないものとします。</li>
          <li>
            当社は、会員に対して会員資格を抹消する場合、当該会員が本サイトで有する全ての権利を抹消するものとします。
            <ol>
              <li>IDまたはパスワードを不正使用した場合</li>
              <li>監督官庁からの営業の取消もしくは停止等の処分を受けたとき</li>
              <li>振り出した手形又は小切手が不渡りになったとき、また銀行取引停止処分を受けたとき</li>
              <li>公租公課の滞納処分を受けたとき</li>
              <li>仮差押え、差押え、仮処分、競売、強制執行等の処分を受けたとき</li>
              <li>破産、民事再生、会社更生等の申し立てをし、又はうけたと</li>
              <li>営業の停止、廃止、会社の解散の決議を行ったとき又は清算手続きに入ったとき</li>
              <li>合併、営業譲渡等、重大な組織の変更があったとき</li>
              <li>当社・会員いずれかの責めに帰すべき事由により居たく業務が著しく遅延し、又は履行不能になったとき</li>
              <li>その他取引を継続しがたいと判断される相当の事由があるとき</li>
              <li>当社への申告、届出内容に虚偽が有った場合</li>
              <li>本サービスに関する料金などの支払債務の履行遅延その他の不履行があった場合</li>
              <li>第9条の禁止行為に違反した場合</li>
              <li>前号の他、本規約その他当社が定める諸規定に違反した場合</li>
              <li>過去に本規約違反などにより会員登録の抹消処分をうけていることが判明した場合</li>
              <li>本サービスの利用にあたり不正または不当な行為のあった場合</li>
            </ol>
          </li>
        </ol>
      </p>
      <br>
      <h3>第15条 権利の帰属</h3>
      <p>
        <ol>
          <li>本サービスのホームページデータおよび本サービスにかかわるシステム、ソフトウェア、アプリケーション、モジュール等（以下、併せて「データ等」といいます。）に関する著作権等の知的財産権、その他一切の権限は、当社に帰属するものとします。これらデータ等を当社の許諾なしに加工、改変、編集、複製、転載、第三者への販売、譲渡、貸与、占有の移転をしてはならないものとします。但し、会員が本サービスを利用する上で使用する会員自身の入力ワードおよび結果データについては当該データ等にはあたりません。</li>
          <li>会員は、いかなる場合であってもシステム等をリバースエンジニアリング、逆コンパイルまたは逆アセンブルしてはならないものとします。</li>
          <li>会員は、本サービスを利用するにあたり、第三者の保有する著作権及び意匠権・商標権等の知的財産権その他の権利を侵害しないように必要な措置を講じるものとします。</li>
          <li>本条は、当社と会員との間の本契約が終了した後も、有効に存続するものとします。</li>
          <li>データの二次利用は原則できません。二次利用とは本サービスを利用して得た結果、もしくはデータそのものを第三者へ情報を開示することが該当します。ただし、対象商品の販売促進を進めるに当たり、特定の関係者へ限定して必要な情報を開示する行為は2次利用にはあたりません。</li>
        </ol>
      </p>
      <br>
      <h3>第16条 制限事項</h3>
      <p>
        <ol>
          <li>会員は原則、法人のお客様に限定させていただきます。ただし、当社が競合企業と判断した先、またはデータ販売・利用に問題があると判断した先へは販売を行わないことがあります。</li>
          <li>データ等は、会員が直接利用されることを前提に販売していますので、自己または第三者の営利を図る目的で転売・貸与することを禁止します。</li>
        </ol>
      </p>
      <br>
      <h3>第17条 権利に関する紛争</h3>
      <p>
        <ol>
          <li>会員は、会員の責に帰する事由により、当社又は第三者との間で著作権及び意匠権・商標権等の知的財産権その他権利侵害の紛争が生じた場合、自己の責任と費用をもってこれを解決するものとします。</li>
          <li>当社は、当社の責に帰する事由により、会員又は第三者との間で著作権及び意匠権・商標権等の知的財産権その他権利侵害の紛争が生じた場合、自己の責任と費用をもってこれを解決するものとします。</li>
          <li>会員・当社は、前二項の紛争発生の事実を知った場合には、直ちにその事実を相互に通知するものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第18条 権利義務譲渡について</h3>
      <p>
        <ol>
          <li>会員は、会員登録により得た地位及び本契約から生じる債権債務を第三者に譲渡してはならないものとします。</li>
          <li>当社は、本サービスの全部または一部を当社の裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で会員のアカウントを含む、本サービスに係る利用者の一切の権利が譲渡先に移転するものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第19条 交換・返品・キャンセル</h3>
      <p>
        <ul>
          <li>本サービスで提供するデータの交換・返品は、販売形態の性質上、プラン決定後にはお受けできませんので、あらかじめご了承ください。但し、お客様の責めに帰すべき事由によらない場合は除きます。プラン決定後に本サービスの契約をキャンセルする場合は、決定から1週間以内且つ予測値算出前に当社までご連絡ください。</li>
        </ul>
      </p>
      <br>
      <h3>第20条 通知</h3>
      <p>
        <ul>
          <li>
            本サイトの利用および本規約に基づく当社から会員宛の諸通知は、会員が申告したＥメールアドレスに当社が発信する方法または当社が別途定める方法によりこれを行うものとします。
            会員は、申告したEメールアドレスその他の登録情報を必要に応じて変更せずこれを怠った場合、当社からの諸通知または送付書類等が延着または不到達となっても、当社が通常到達すべきときに到達したものとみなすことに異議がないものとします。
          </li>
        </ul>
      </p>
      <br>
      <h3>第21条 免責事項</h3>
      <p>
        <ol>
          <li>当社は、本サービスにより当社が会員に提供した結果に基づき会員が実施した一切の行為についてその責任を負わないものとします。</li>
          <li>当社は、本サービスを利用したことにより、会員が直接的または間接的に発生した損害において、一切の賠償責任を負わないものとします。</li>
          <li>当社は、会員が本サービスを利用するにあたり使用する通信機器及びソフトウェアとの互換性を確保するために、当社の管理する設備、システム又はソフトウェアを改造、変更又は追加したり、本サービスの提供方法を変更する義務を負わないものとします。</li>
          <li>当社は、サイト、サーバー、各種コンテンツ、当社の本サイトから送信されたEメールが、ウイルス、またはその他の有害な要素に感染していないことを保証いたしません。</li>
          <li>当社は、直接的、間接的、付随的、懲罰的、必然的な損害を含みこれに限定されない、当サイトの使用から生じるいかなる種類の損害に対しても責任を負うものではありません。</li>
          <li>会員は、本規約その他諸規定等に違反し、他の会員または第三者に対して損害を与えたものとして、当該会員または第三者から何らかの請求がなされ、または訴訟が提起された場合、会員は自らの費用と責任において当該請求または訴訟を解決するものとし、当社は一切の責任を負わないものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第22条 本サービスの内容の変更ならびに本サービスの一時中断、停止および中止</h3>
      <p>
        <ul>
          <li>
            当社は、本サービスの稼動状態を良好に保つために、次の各号に該当する場合、会員に対して事前に通知を行うことなく、本サービスの全部あるいは一部の変更または、本サービスの提供の全部あるいは一部を停止することができるものとします。
          </li>
        </ul>
        <ol>
          <li>本サービスを提供するための装置、システムの保守点検や更新のために必要な場合</li>
          <li>火災、停電、第三者による妨害行為などにより本サービスの提供が困難になった場合</li>
          <li>本サービスの通信に係る事業者による通信回線の提供が中断、停止され、本サービスの提供が困難となった場合</li>
          <li>裁判所、その他公的機関からの要請または法令に基づき本サービスの一時中断、停止または中止を命じられた場合</li>
          <li>その他、やむを得ず本サービスの全部もしくは一部の一時中断、または停止が必要であると当社が判断した場合</li>
          <li>前項に基づく本サービスの内容の変更または、一時中断、停止または中止よって会員に不利益または損害が発生した場合においても、当社は一切の責任を負わないものとします。</li>
        </ol>
      </p>
      <br>
      <h3>第23条 準拠法</h3>
      <p>
        <ul>
          <li>本規約は、日本法に準拠して解釈されるものとします。</li>
        </ul>
      </p>
      <br>
      <h3>第24条 専属的合意管轄裁判所</h3>
      <p>
        <ul>
          <li>当社と会員の間で本規約に関連して紛争が生じた場合は、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄裁判所といたします。</li>
        </ul>
      </p>
      <br>
      <br>
      <p class="text-right">
        2021年10月1日施行
        <br>
        以上
      </p>
    </section>
  </vue-perfect-scrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
        wheelPropagation: false
      }
    }
  }
}
</script>

<style lang="scss">
.scroll-area  {
  .inneriframe {
    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
    }
  }
  p {
    margin-left: 25px;
  }
  ol {
    list-style-type: decimal;
    margin-left: 17px;

    li {
      list-style: decimal;
      line-height: 1.3;
      margin-bottom: 1em;
    }
    & + ol {
      margin-top: 10px;
    }
  }
  ul {
    li {
      line-height: 1.3;
      margin-bottom: 1em;
    }
  }
  .inneriframe {
    p {
      margin-bottom: 10px;
    }
    ul.list-style-type-disc {
      list-style-type: disc;
    }
  }
  .text-red {
    color: red;
  }
  .text-right {
    text-align: right;
    margin-bottom: 1em;
  }
}
</style>
