<template>
  <main id="register">
    <div class="container">
      <form class="form-horizontal mb20" novalidate @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel">
              <div class="panel-heading">
                <h2 class="pageTitle">
                  登録情報の入力<span>register</span>
                </h2>
              </div>
              <div class="panel-body">
                <template v-if="isValidUrl === true">
                  <div class="form-group" :class="{'has-error': errors.organization_name}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>会社名・団体名
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input v-model="user.organization_name" type="text" class="form-control" placeholder="株式会社プラグ" @input="clearMessage('organization_name')">
                      <div class="help-block with-errors">
                        <template v-if="errors.organization_name">
                          {{ errors.organization_name }}
                        </template>
                        <template v-else>
                          個人の場合は「なし」とご記入下さい
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.branch_office}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>本社・支社・支店・研究所
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input v-model="user.branch_office" type="text" class="form-control" placeholder="東京支社" @input="clearMessage('branch_office')">
                      <div v-if="errors.branch_office" class="help-block with-errors">
                        {{ errors.branch_office }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.department_name}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>部署
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input v-model="user.department_name" type="text" class="form-control" placeholder="デザイン部" @input="clearMessage('department_name')">
                      <div v-if="errors.department_name" class="help-block with-errors">
                        {{ errors.department_name }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.first_name || errors.last_name}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>お名前
                    </label>
                    <div class="col-sm-4 col-md-4">
                      <input v-model="user.last_name" type="text" class="form-control" placeholder="山田" @input="clearMessage('last_name')">
                      <div v-if="errors.last_name" class="help-block with-errors">
                        {{ errors.last_name }}
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-4">
                      <input v-model="user.first_name" type="text" class="form-control" placeholder="太郎" @input="clearMessage('first_name')">
                      <div v-if="errors.first_name" class="help-block with-errors">
                        {{ errors.first_name }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.first_name_jp || errors.last_name_jp}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>ふりがな
                    </label>
                    <div class="col-sm-4 col-md-4">
                      <input v-model="user.last_name_jp" type="text" class="form-control" placeholder="やまだ" @input="clearMessage('last_name_jp')">
                      <div v-if="errors.last_name_jp" class="help-block with-errors">
                        {{ errors.last_name_jp }}
                      </div>
                    </div>
                    <div class="col-sm-4 col-md-4">
                      <input v-model="user.first_name_jp" type="text" class="form-control" placeholder="たろう" @input="clearMessage('first_name_jp')">
                      <div v-if="errors.first_name_jp" class="help-block with-errors">
                        {{ errors.first_name_jp }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.zip_code}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>郵便番号
                    </label>
                    <div class="col-sm-3 col-md-3">
                      <input v-model="user.zip_code" type="text" class="form-control halfchar" name="postalcode" placeholder="101-0051" maxlength="8" @input="clearMessage('zip_code')">
                      <div v-if="errors.zip_code" class="help-block with-errors">
                        {{ errors.zip_code }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.address}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>住所
                    </label>
                    <div class="col-sm-8 col-md-8">
                      <input v-model="user.address" type="text" class="form-control halfchar" name="address" placeholder="東京都千代田区神田神保町1-3-5" @input="clearMessage('address')">
                      <div v-if="errors.address" class="help-block with-errors">
                        {{ errors.address }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.building_address}">
                    <label class="col-sm-3 col-md-3 control-label">
                      建物名など
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input v-model="user.building_address" type="text" class="form-control halfchar" placeholder="冨山房ビル3F" @input="clearMessage('building_address')">
                      <div v-if="errors.building_address" class="help-block with-errors">
                        {{ errors.building_address }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.phone_number}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>電話番号
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input v-model="user.phone_number" type="tel" class="form-control halfchar" placeholder="0312345678" maxlength="20" @input="clearMessage('phone_number')">
                      <div v-if="errors.phone_number" class="help-block with-errors">
                        {{ errors.phone_number }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-sm-3 col-md-3 control-label">
                      メールアドレス(ログインID)
                    </label>
                    <div class="col-sm-7 col-md-7">
                      <p class="register_chk">
                        {{ user.email }}
                      </p>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.password}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>パスワード
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input id="inputPassword" v-model="user.password" type="password" class="form-control" maxlength="20" @input="clearMessage('password')">
                      <div v-if="errors.password" class="help-block with-errors">
                        {{ errors.password }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.re_password}">
                    <label class="col-sm-3 col-md-3 control-label">
                      <span class="required">必須</span>パスワード再入力
                    </label>
                    <div class="col-sm-6 col-md-4">
                      <input v-model="user.re_password" type="password" class="form-control" data-match="#inputPassword" maxlength="20" @input="clearMessage('re_password')">
                      <div v-if="errors.re_password" class="help-block with-errors">
                        {{ errors.re_password }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.others}">
                    <label class="col-sm-3 col-md-3 control-label">
                      その他
                    </label>
                    <div class="col-sm-9 col-md-9">
                      <textarea v-model="user.others" class="form-control" rows="6" maxlength="1000" @input="clearMessage('others')" />
                      <div v-if="errors.others" class="help-block with-errors">
                        {{ errors.others }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-sm-3 col-md-3 control-label">
                      利用規約
                    </label>
                    <div class="col-sm-9 col-md-9">
                      <terms-of-service />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-sm-3 col-md-3 control-label">
                      利用規約同意確認
                    </label>
                    <div class="checkbox-group col-sm-9">
                      <div class="checkbox clip-check check-primary checkbox-table">
                        <input v-model="privacy_agreement" type="checkbox">
                        <label @click="privacy_agreement = !privacy_agreement">利用規約に同意しました</label>
                      </div>
                      <div class="help-block with-errors" />
                    </div>
                  </div>
                  <ul class="actionVertical clearfix">
                    <li>
                      <button type="submit" :class="['btn', 'btn01', privacy_agreement ? '' : 'disable']">
                        確認画面へ
                      </button>
                    </li>
                  </ul>
                </template>
                <template v-if="isValidUrl === false">
                  このURLは無効になりました。
                  <br>
                  再度、登録をお願いします。
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { getRegisterInfoApi, validateUserInfoApi } from '../../services/api'
import messageUtils from '../../shared/mixins/messageUtils'
import { actions as actionsUser } from '../../store/modules/user'
import store from '../../store'
import { actions as actionsApp } from '../../store/modules/app'
import TermsOfService from './TermsOfService'

export default {
  name: 'Register',
  components: {
    TermsOfService
  },
  mixins: [messageUtils],
  data() {
    return {
      user: {
        email: '',
        password: '',
        re_password: '',
        username: '',
        first_name: '',
        last_name: '',
        first_name_jp: '',
        last_name_jp: '',
        organization_name: '',
        branch_office: '',
        department_name: '',
        address: '',
        building_address: '',
        zip_code: '',
        role: 1,
        phone_number: '',
        others: '',
        token: ''
      },
      privacy_agreement: false,
      isValidUrl: null
    }
  },
  async created() {
    await store.dispatch(actionsApp.SET_LOADING, { active: true })
    const res = await getRegisterInfoApi({
      token: this.$route.params.token
    })
    await store.dispatch(actionsApp.SET_LOADING, { active: false })
    if (res.status === 200) {
      this.user.email = res.data.email
      this.user.username = res.data.email
      this.user.token = this.$route.params.token
      this.isValidUrl = true
    } else {
      this.isValidUrl = false
    }
  },
  mounted() {
    this.clearMessage()
    this.user = this.$store.getters.userRegister
    this.privacy_agreement = this.$route.params.privacy_agreement || false
  },
  methods: {
    async onSubmit() {
      if (this.isValidUrl && this.privacy_agreement) {
        const dataUser = {
          email: this.user.email ? this.user.email.trim() : '',
          password: this.user.password || '',
          re_password: this.user.re_password || '',
          username: this.user.username ? this.user.username.trim() : '',
          first_name: this.user.first_name ? this.user.first_name.trim() : '',
          last_name: this.user.last_name ? this.user.last_name.trim() : '',
          first_name_jp: this.user.first_name_jp ? this.user.first_name_jp.trim() : '',
          last_name_jp: this.user.last_name_jp ? this.user.last_name_jp.trim() : '',
          organization_name: this.user.organization_name ? this.user.organization_name.trim() : '',
          branch_office: this.user.branch_office ? this.user.branch_office.trim() : '',
          department_name: this.user.department_name ? this.user.department_name.trim() : '',
          address: this.user.address ? this.user.address.trim() : '',
          building_address: this.user.building_address ? this.user.building_address.trim() : '',
          zip_code: this.user.zip_code ? this.user.zip_code.trim() : '',
          role: this.user.role || 1,
          phone_number: this.user.phone_number ? this.user.phone_number.trim() : '',
          others: this.user.others ? this.user.others.trim() : '',
          token: this.user.token || ''
        }
        await store.dispatch(actionsApp.SET_LOADING, { active: true })
        const res = await validateUserInfoApi(dataUser)
        await store.dispatch(actionsApp.SET_LOADING, { active: false })
        if (res.status === 200) {
          await this.$store.dispatch(actionsUser.SET_USER_REGISTER, dataUser)
          await this.$router.push({
            name: 'RegisterConfirm'
          })
        } else {
          this.updateMessage(res.status, res.data)
        }
      }
    }
  }
}
</script>

<style scoped>
.disable {
  cursor: not-allowed;
}
</style>
